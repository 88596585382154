<template>
  <div id="calender" class="register">

    <main>
      <section class="calendar">
        <div id="activity_calendar" class="w1200">

          <div class="calendar_filter">
            <!-- <button class="prev_year" @click="prevYear">
              <img src="@/statics/img/icon/down_b.svg" alt="">
            </button> -->
            <month-picker-input
              :months="monthArr"
              :default-month="currentMonth()"
              :default-year="currentYear()"
              :input-pre-filled="true"
              v-model="datePicked"
            ></month-picker-input>
            <!-- <button class="next_year" @click="nextYear">
              <img src="@/statics/img/icon/down_b.svg" alt="">
            </button> -->
          </div>

          <v-calendar
            ref="calendar"
            class="custom-calendar max-w-full"
            :masks="masks"
            :attributes="attributes"
            disable-page-swipe
            is-expanded
            trim-weeks
          >
            <template v-slot:day-content="{ day, attributes }">
              <div class="flex flex-col h-full z-10 overflow-hidden">
                <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                <div class="flex-grow overflow-y-auto overflow-x-auto">
                  <div
                    v-for="(attr, index) in attributes"
                    class="event_box text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                    :class="attr.customData.class"
                    :key="index"
                  >
                    <router-link :to="`/register/event/info?event_id=${attr.customData.uuid}`">
                      <p class="card_fraction small type-A" v-if="attr.customData.fraction_a">
                        <span class="tag">A類</span>
                        <span class="credit">．{{ attr.customData.fraction_a }}學分</span>
                      </p>
                      <p class="card_fraction small type-B" v-if="attr.customData.fraction_b">
                        <span class="tag">B類</span>
                        <span class="credit">．{{ attr.customData.fraction_b }}學分</span>
                      </p>
                      <p class="wordBreak">{{ attr.customData.title }}</p>
                    </router-link>
                  </div>
                </div>
              </div>
            </template>
          </v-calendar>
        </div>

      </section>
    </main>

  </div>
</template>

<script>
import '@/assets/scss/calendar.scss';
import moment from 'moment';
import { MonthPickerInput } from 'vue-month-picker';
import {
  // eventsIndex,
  calendar,
} from '@/lib/http';

// const month = new Date().getMonth();
// const year = new Date().getFullYear();

export default {
  name: 'RegisterCalender',
  data() {
    return {
      currentDate: moment().format('YYYY-MM'),
      datePicked: '',
      monthArr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      masks: {
        weekdays: 'W',
      },
      attributes: [
        // {
        //   key: 1,
        //   customData: {
        //     title: 'Lunch with mom.',
        //     class: 'bg-red-600 text-white',
        //   },
        //   dates: new Date(year, month, 1),
        // },
        // {
        //   key: 2,
        //   customData: {
        //     title: 'Take Noah to basketball practice',
        //     class: 'bg-blue-500 text-white',
        //   },
        //   dates: new Date(year, month, 2),
        // },
        // {
        //   key: 3,
        //   customData: {
        //     title: "Noah's basketball game.",
        //     class: 'bg-blue-500 text-white',
        //   },
        //   dates: new Date(year, month, 5),
        // },
        // {
        //   key: 4,
        //   customData: {
        //     title: 'Take car to the shop',
        //     class: 'bg-indigo-500 text-white',
        //   },
        //   dates: new Date(year, month, 5),
        // },
        // {
        //   key: 4,
        //   customData: {
        //     title: 'Meeting with new client.',
        //     class: 'bg-teal-500 text-white',
        //   },
        //   dates: new Date(year, month, 7),
        // },
        // {
        //   key: 5,
        //   customData: {
        //     title: "Mia's gymnastics practice.",
        //     class: 'bg-pink-500 text-white',
        //   },
        //   dates: new Date(year, month, 11),
        // },
        // {
        //   key: 6,
        //   customData: {
        //     title: 'Cookout with friends.',
        //     class: 'bg-orange-500 text-white',
        //   },
        //   dates: { months: 5, ordinalWeekdays: { 2: 1 } },
        // },
        // {
        //   key: 7,
        //   customData: {
        //     title: "Mia's gymnastics recital.",
        //     class: 'bg-pink-500 text-white',
        //   },
        //   dates: new Date(year, month, 22),
        // },
        // {
        //   key: 8,
        //   customData: {
        //     title: 'Visit great grandma.',
        //     class: 'bg-red-600 text-white',
        //   },
        //   dates: new Date(year, month, 25),
        // },
      ],
    };
  },
  created() {
    /** 預設篩選此年此月 */
    this.filterByDate(moment().format('YYYY-MM'));
  },
  mounted() {
    const currentYear = moment().format('YYYY-MM').split('-')[0];
    const currentMonth = moment().format('YYYY-MM').split('-')[1];
    this.$refs.calendar.move({ month: currentMonth, year: currentYear });
  },
  computed: {
    // currentYear() {
    //   // return parseInt(moment().format('YYYY-MM').split('-')[0], 10);
    //   return parseInt(this.currentDate.split('-')[0], 10);
    // },
    // currentMonth() {
    //   // return parseInt(moment().format('YYYY-MM').split('-')[1], 10);
    //   return parseInt(this.currentDate.split('-')[1], 10);
    // },
  },
  methods: {
    currentYear() {
      // return parseInt(moment().format('YYYY-MM').split('-')[0], 10);
      return parseInt(this.currentDate.split('-')[0], 10);
    },
    currentMonth() {
      // return parseInt(moment().format('YYYY-MM').split('-')[1], 10);
      return parseInt(this.currentDate.split('-')[1], 10);
    },
    async prevYear() {
      await this.$refs.calendar.move(-1);
    },
    async nextYear() {
      this.currentDate = moment().add(1, 'months').format('YYYY-MM');
      await this.$refs.calendar.move(1);
    },
    filterByDate(date) {
      calendar({ date }).then((result) => {
        console.log('calendar', result);
        this.attributes = result.data.result.data.map((item, index) => ({
          key: index + 1,
          customData: {
            uuid: item.uuid,
            title: item.name,
            fraction_a: item.fraction_a,
            fraction_b: item.fraction_b,
            // class: 'bg-red-600 text-white',
          },
          dates: new Date(item.start_date),
        }));
      });
    },
  },
  components: {
    MonthPickerInput,
  },
  watch: {
    datePicked() {
      const yearPicked = this.datePicked.year;
      const monthPicked = this.datePicked.monthIndex;
      const datePicked = moment(this.datePicked.from).format('YYYY-MM');
      this.$refs.calendar.move({ month: monthPicked, year: yearPicked });
      this.filterByDate(datePicked);
    },
  },
};
</script>
